import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GEA_MES_NAVBAR_APPLICATION_NAME,GEA_MES_NAVBAR_MENU_SERVICE,GEA_MES_NAVBAR_ENV_PROD_STATUS, GEA_ENVIROMENT, NavItem} from '@gea-mes/navbar';
import { GeaMesLoginComponent, GeaMesCognitoAuthGuard, GeaMesCognitoModule, GeaMesAuthInterceptor, COGNITO_CONFIG_HA, APPLICATION_NAME, ALLOW_SCREENAUTH, APIGATEWAY_CONFIG } from '@gea-mes/cognito';
import { LocationStrategy, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// Components
import { ObjectsComponent } from './admin/components/objects/objects.component';
import { AdminComponent } from './admin/components/admin/admin.component';
import { ProblemsComponent } from './admin/components/problems/problems.component';
import { ProblemGroupsComponent } from './admin/components/problem-groups/problem-groups.component';
import { TeamGroupsComponent } from './admin/components/team-groups/team-groups.component';
import { MetricsComponent } from './components/metrics/metrics.component';
import { ObjectListComponent } from './components/object-list/object-list.component';
import { ProblemListComponent } from './components/problem-list/problem-list.component';
import { PaMatrixComponent } from './pa-matrix/components/pa-matrix/pa-matrix.component';
import { TestRepairComponent } from './components/test-repair/test-repair.component';
import { InventoryComponent } from './inventory/components/inventory/inventory.component';
import { appinfo } from './appinfo';
import { BehaviorSubject } from 'rxjs';
import { AnalysisHomeComponent } from './analysis/components/analysis-home/analysis-home.component';
import { PlannedDowntimeComponent } from './components/oa/planned-downtime/planned-downtime.component';
import { CustomerDemandComponent } from './components/oa/customer-demand/customer-demand.component';

const appRoutes: Routes = [
  {path: 'login', component: GeaMesLoginComponent},
  {path: 'objectlist',component:ObjectListComponent,canActivate: [GeaMesCognitoAuthGuard]},
  {path: 'objectlist/:plant_name',component:ObjectListComponent,canActivate: [GeaMesCognitoAuthGuard]},
  {
    path: 'admin', canActivate: [GeaMesCognitoAuthGuard],
    children: [
      {path: '', component: AdminComponent},
      {path: 'objectlist', component: ObjectsComponent},
      {path: 'problemgroups', component: ProblemGroupsComponent},
      {path: 'problems/:problemgroup/:description', component: ProblemsComponent},
      {path: 'teamgroups', component: TeamGroupsComponent}
    ]   
  },
  {
    path: 'oa', canActivate: [GeaMesCognitoAuthGuard],
    children: [
      {path: 'planneddowntime/:site/:objectid', component: PlannedDowntimeComponent},
      {path: 'customerdemand/:site/:objectid', component: CustomerDemandComponent}
    ]   
  },
  {path: 'metrics/:site/:objectid', canActivate: [GeaMesCognitoAuthGuard], component: MetricsComponent},
  {path: 'problemlist/:site/:objectid/:hour', canActivate: [GeaMesCognitoAuthGuard], component: ProblemListComponent},
  {path: 'pamatrix/:site/:objectid', canActivate: [GeaMesCognitoAuthGuard], component: PaMatrixComponent },
  {path: 'testrepair/:site/:objectid', canActivate: [GeaMesCognitoAuthGuard], component: TestRepairComponent },
  {path: 'inventory/:site/:objectid', canActivate: [GeaMesCognitoAuthGuard], component: InventoryComponent },
  {path: 'analysis/:site/:objectid', component: AnalysisHomeComponent },
  {path: '', redirectTo:'/objectlist', pathMatch: 'full'},
  {path: '**', redirectTo:'/objectlist'}

];

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  imports: [GeaMesCognitoModule, RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  providers: [
    { provide: COGNITO_CONFIG_HA, useValue: environment.cognitoOptionsHA },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
    { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
    { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
    { provide: ALLOW_SCREENAUTH, useValue: environment.AllowScreenAuth },
    { provide: GEA_MES_NAVBAR_APPLICATION_NAME,useValue:appinfo.name.toUpperCase()}, 
     { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
     { provide: GEA_ENVIROMENT, useValue: environment },
     { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue: new BehaviorSubject<NavItem[]>([]) }    


  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
